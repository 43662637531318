document.addEventListener('DOMContentLoaded', function () {

    if (document.querySelector('.read-more-btn') && document.querySelector('.text-container')) {

        const readMoreBtn = document.querySelector('.read-more-btn');
        const textContainer = document.querySelector('.text-container');

        readMoreBtn.addEventListener('click', function () {
            textContainer.classList.toggle('text-expanded');

            if (textContainer.classList.contains('text-expanded')) {
                readMoreBtn.textContent = myLocalizedText.readLess;
            } else {
                readMoreBtn.textContent = myLocalizedText.readMore;
            }
            console.log('aaa');
        });
    }

    document.querySelectorAll('.accordion-item').forEach(item => {
        const title = item.querySelector('.accordion-title');
        title.addEventListener('click', () => {
            const content = item.querySelector('.accordion-content');
            const isActive = content.classList.contains('is-active');

            // Get all accordion items
            const items = document.querySelectorAll('.accordion-item');

            // Remove 'is-active' class from all items
            items.forEach(i => i.querySelector('.accordion-content').classList.remove('is-active'));

            // Toggle the current item's content visibility
            if (!isActive) content.classList.add('is-active');
        });
    });
});